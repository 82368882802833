import * as React from 'react';
import * as bottomLeft from './bottom_left.png';
import * as bottomRight from './bottom_right.png';
import * as cn from 'classnames';
import * as topLeft from './top_left.png';
import * as topRight from './top_right.png';

import { Col, Row } from 'react-grid-system';
import { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Divider from '../../Text/Divider';
import Spacer from '../../Text/Spacer';

const Customise = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.content}>
            <div className={cn(classes.withPadding, classes.textCenter)}>
                <h1>Customise your information edge</h1>
                <Divider centered={true} />
                <h3>
                    Access to information is no longer enough. Vortexa's platform gives you the ability to use your
                    experience to create your own view of where the market is, giving you the edge you need.
                </h3>
            </div>
        </div>

        <Spacer />

        <div className={classes.content} style={{ maxWidth: '760px' }}>
            <Row nogutter>
                <Col xs={12} sm={12} md={6} align="center">
                    <div className={classes.imageCell}>
                        <h3 className={classes.imageCellContent}>
                            Create customisable tabs, by product or analysis-type
                        </h3>
                        <img src={topLeft} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} align="center">
                    <div className={classes.imageCell}>
                        <h3 className={classes.imageCellContent}>Configure a wide set of alerts and notifications</h3>
                        <img src={topRight} />
                    </div>
                </Col>
            </Row>
            <Row nogutter>
                <Col xs={12} sm={12} md={6} align="center">
                    <div className={classes.imageCell}>
                        <h3 className={classes.imageCellContent}>Configure custom filters</h3>
                        <img src={bottomLeft} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} align="center">
                    <div className={classes.imageCell}>
                        <h3 className={classes.imageCellContent}>
                            Our sleek and easy-to-use interface is highly customisable, allowing users to draw out
                            powerful individual insights
                        </h3>
                        <img src={bottomRight} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
);

export default Customise;
