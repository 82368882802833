import * as React from 'react';

import { Helmet } from 'react-helmet';

/**
 * Sets header tags for any page it is included in
 * @param {string} title - The title of the page
 * @param {string} description - The description of the page
 */
const HelmetComponent = ({ title, description }: { title: string; description: string }) => (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="text/javascript" src="quantcastChoiceGDPR.js" />
        <link rel="stylesheet" type="text/css" href="quancastChoiceGDPR.css" />
    </Helmet>
);

export default HelmetComponent;
