import * as React from 'react';
import * as cn from 'classnames';
import * as moment from 'moment/moment';

import { ArticleNode, Edge, MyTheme } from 'src/types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import Link from 'gatsby-link';
import { SUBSCRIBE_SECTION_ID } from '../Subscribe/SubscribeBanner';

type OwnProps = {
    articles?: Edge<ArticleNode>;
};

const ArticlePreview = ({ node, classes }: { node: any; classes: any }) => (
    <div className={classes.articlePreview}>
        <div className={classes.date}>
            <span>{moment(node.published).format('MMM')}</span>
            <h1>{moment(node.published).format('D')}</h1>
            <span>{moment(node.published).format('YYYY')}</span>
        </div>
        <div className={classes.text}>
            <h4>{node.title}</h4>
            <Link className={classes.clickable} to={`/insight/${node.slug}`}>
                Read more
            </Link>
        </div>
    </div>
);

const ArticleBar = ({ articles, classes }: OwnProps & WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.articles}>
            {articles &&
                articles.edges.map(({ node }) => {
                    return <ArticlePreview node={node} classes={classes} key={node.slug} />;
                })}
            <div className={cn(classes.articlePreview, !articles ? classes.subscribeOnly : undefined)}>
                <div className={classes.subscribeText}>
                    Subscribe and keep up to date with our latest news, or read{' '}
                    <Link to={'/insight'} className={classes.link}>
                        our insights here
                    </Link>
                </div>
                <a href={`#${SUBSCRIBE_SECTION_ID}`}>
                    <Button>Subscribe</Button>
                </a>
            </div>
        </div>
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    articlePreview: {
        display: 'flex',
        alignItems: 'center',
        width: '300px'
    },
    subscribeOnly: {
        width: '100%',
        justifyContent: 'center'
    },
    text: {
        lineHeight: '1.5em',
        marginLeft: theme.basePadding
    },
    link: {
        color: theme.palette.vortexa.primary
    },
    articles: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.fog.primary}`,
        '& > *': {
            margin: `${2 * theme.basePadding}px`
        }
    },
    clickable: {
        color: theme.palette.vortexa.active,
        textDecoration: 'none'
    },
    subscribeText: {
        marginRight: theme.basePadding
    },
    date: {
        paddingRight: theme.basePadding,
        verticalAlign: 'bottom',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.palette.fog.primary}`
    }
});

export default injectSheet(styles)(ArticleBar);
