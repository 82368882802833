import * as React from 'react';
import * as defaultLogo from '../../assets/Logo_news.png';

import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import { MyTheme, News } from 'src/types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import HighlightedText from '../Text/Highlighted';
import Spacer from '../Text/Spacer';

type OwnProps = {
    news: News[];
};

const DEFAULT_VISIBLE_NEWS = 3;
const DEFAULT_VISIBLE_INCREMENT = 3;

const NewsComponent = (props: WithSheet<StyleCreator<string, any>> & OwnProps) => {
    const { news, classes } = props;

    const [visibleCount, setVisibleCount] = React.useState<number>(DEFAULT_VISIBLE_NEWS);
    return (
        <div className={classes.container}>
            <div className={classes.introduction}>
                <HighlightedText highlightedText={'in the news'} />
                <Spacer />
            </div>
            <Container fluid>
                <Row>
                    {news.map((node: News, index: number) => {
                        if (index >= visibleCount) {
                            return;
                        }
                        const image = node.icon ? node.icon : defaultLogo;
                        return (
                            <Col sm={12} key={`news-${index}`}>
                                <div className={classes.newsItem}>
                                    <img className={classes.icon} src={image} />
                                    <div className={classes.title}>{node.title}</div>
                                    <a className={classes.linkButton} href={node.link} target="_blank">
                                        <Hidden xs sm>
                                            <Button>Read more</Button>
                                        </Hidden>
                                        <Visible xs sm>
                                            Read more
                                        </Visible>
                                    </a>
                                </div>
                            </Col>
                        );
                    })}
                    {visibleCount < news.length && (
                        <Button
                            onClick={() => setVisibleCount(visibleCount + DEFAULT_VISIBLE_INCREMENT)}
                            style={{ margin: `16px auto` }}
                        >
                            Show more
                        </Button>
                    )}
                </Row>
            </Container>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    container: theme.content,
    introduction: {
        margin: `0px auto`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    newsItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.fog.primary}`,
        padding: `${theme.basePadding}px 0`
    },
    icon: {
        display: 'flex',
        width: '75px'
    },
    title: {
        flexGrow: 1,
        margin: `${theme.cardContentPadding}px`,
        maxWidth: '80%'
    },
    '@media (max-width: 600px)': {
        introduction: {
            marginBottom: '-30px'
        },
        newsItem: {
            padding: `${2 * theme.basePadding}px 0`
        },
        icon: {
            display: 'none'
        },
        linkButton: {
            width: '100px',
            textAlign: 'right',
            color: theme.palette.vortexa.primary
        },
        title: {
            margin: 0
        }
    },
    dividerContainer: {
        width: '50%'
    }
});

export default injectSheet(styles)(NewsComponent);
