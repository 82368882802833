import * as Bkg from './feat_intro_bkg.png';
import * as React from 'react';
import * as Screen from './feat_intro_screen.jpg';
import * as cn from 'classnames';

import { Col, Row } from 'react-grid-system';
import { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Divider from '../../Text/Divider';
import Spacer from '../../Text/Spacer';

const Introduction = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.content}>
            <div className={classes.withPadding}>
                <p>
                    As a participant in the energy markets, you want to see what others cannot.
                    This requires a truly comprehensive data set of waterborne movements.
                </p>
                <p>
                    Our advanced analytics platform is created by a global team of leading energy market experts,
                    data scientists and engineers. Vortexa has assembled the most complete set of empirical data
                    on cargoes available, and combined it with the most advanced data science methods to complete
                    the picture. That means our solution addresses real needs with accuracy and it constantly
                    evolves to help you identify risks and opportunities for your business.
                </p>
            </div>
        </div>

        <Spacer />

        <div
            style={{ background: `url(${Bkg}) repeat-x`, backgroundSize: 'contain' }}
            className={cn(classes.featureImageContainer, classes.introductionImage)}
        >
            <img src={Screen} className={cn(classes.withFlex, classes.introductionImage)} />
        </div>

        <Spacer />

        <div className={classes.content}>
            <div className={cn(classes.withPadding, classes.textCenter)}>
                <h1 className={classes.sectionHeader}>Human expertise, AI scaled</h1>
                <Divider centered={true} />
                <h3>
                    Deep industry expertise combined with state-of-the-art technology to deliver market insights before
                    anyone else.
                </h3>
                <Spacer />
                <Row justify="between" className={classes.textLeft}>
                    <Col xs={12} sm={12} md={6}>
                        We show you specific details of an oil cargo on-board a vessel and track it from its terminal of
                        origin to its destination – for the whole world, in real-time.
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        Our clients can make better trading, investment and analytical decisions with a significant
                        information edge.
                    </Col>
                </Row>
            </div>
        </div>
    </div>
);

export default Introduction;
