import * as Icons from '../../utils/icons';
import * as React from 'react';
import * as cn from 'classnames';

import { Col, Container, Row } from 'react-grid-system';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import { MyTheme } from '../../types/types';
import { validateEmail } from '../../utils/form';

// import * as loaderSvg from '../../assets/loader.svg'

const RequestDemoText = {
    header: 'Contact us',
    text: 'Thank you for your interest. Please complete the form below and we will be in touch with you shortly'
};

type OwnProps = {
    open?: boolean;
    close?: () => void;
};

class ContactUsForm extends React.PureComponent<OwnProps & WithSheet<StyleCreator<string, any>>> {
    state = {
        name: '',
        email: '',
        tel: '',
        organisation: '',
        error: false,
        errorMessage: '',
        loader: false,
        message: ''
    };

    onChangeHandler = (e: any) => {
        e.preventDefault();
        this.setState({ [e.currentTarget.id]: e.currentTarget.value, error: false });
    };

    onSubmitHandler = () => {
        const { close } = this.props;
        if (validateEmail(this.state.email)) {
            this.setState({ loader: true });
            try {
                const url = 'https://formkeep.com/f/74e0fd724717';
                const headers = {
                    accept: 'application/javascript',
                    'Content-Type': 'application/json'
                };
                const payload = {
                    name: this.state.name,
                    email: this.state.email,
                    tel: this.state.tel,
                    organisation: this.state.organisation,
                    message: this.state.message
                };
                fetch(url, {
                    method: 'post',
                    headers,
                    body: JSON.stringify(payload)
                }).then(() => {
                    this.setState({ loader: false });
                    if (close) {
                        close();
                    }
                });
            } catch (error) {
                this.setState({
                    error: true,
                    loader: false,
                    errorMessage: 'There was a problem, please try again'
                });
            }
        } else {
            this.setState({ error: true, errorMessage: 'Please double check your email and try again.' });
        }
    };

    render() {
        const { classes } = this.props;
        const { name, email, tel, organisation, error, errorMessage, loader } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <h1>{RequestDemoText.header}</h1>
                    <p>{RequestDemoText.text}</p>
                </div>
                <Container className={classes.gridContainer}>
                    <Row className={classes.row}>
                        <Col xs={12} md={6}>
                            <div className={classes.inputContainer}>
                                <input
                                    type="text"
                                    value={name}
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    required
                                    className={classes.input}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={6}>
                            <div className={classes.inputContainer}>
                                <input
                                    type="email"
                                    value={email}
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    required
                                    className={classes.input}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.row}>
                        <Col xs={12} md={6} className={classes.col}>
                            <div className={classes.inputContainer}>
                                <input
                                    type="tel"
                                    value={tel}
                                    id="tel"
                                    name="tel"
                                    placeholder="Phone Number"
                                    required
                                    className={classes.input}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={6}>
                            <div className={classes.inputContainer}>
                                <input
                                    type="text"
                                    value={organisation}
                                    id="organisation"
                                    name="organisation"
                                    placeholder="Organisation"
                                    required
                                    className={classes.input}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.row}>
                        <Col xs={12}>
                            <div className={classes.inputContainer}>
                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Your message"
                                    onChange={this.onChangeHandler}
                                    className={cn(classes.input, classes.textArea)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={classes.buttonContainer}>
                        {error && (
                            <div className={classes.errorMessage}>
                                <span>{errorMessage}</span>
                            </div>
                        )}
                        {loader ? (
                            Icons.loader()
                        ) : (
                            <Button
                                style={{ display: 'flex', justifyContent: 'center' }}
                                primary
                                onClick={this.onSubmitHandler}
                                disabled={loader}
                            >
                                Send
                            </Button>
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    container: {
        position: 'relative',
        height: '100%'
    },
    gridContainer: {
        paddingRight: '31px'
    },
    header: {
        width: '100%',
        color: theme.palette.fog.primary,
        textAlign: 'left',
        marginLeft: 2 * theme.basePadding,
        marginTop: 5 * theme.basePadding
    },
    errorMessage: {
        textAlign: 'center',
        color: theme.palette.medusa.primary
    },
    inputContainer: {
        margin: `${theme.basePadding}px 0`,
        padding: theme.basePadding,
        backgroundColor: theme.palette.bigBlue.canvasHighlight
    },
    input: {
        width: '100%',
        height: '30px',
        color: theme.palette.basic.white,
        backgroundColor: theme.palette.bigBlue.canvasHighlight,
        border: 'none'
    },
    buttonContainer: {
        margin: theme.basePadding,
        width: '100%',
        textAlign: 'center'
    },
    textArea: {
        height: '100px',
        '@media (min-width: 350px)': {
            height: '120px'
        }
    }
});

export default injectSheet(styles)(ContactUsForm);
