import * as React from 'react';
import * as cn from 'classnames';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Customise from './Customise/Customise';
import Flows from './Flows/Flows';
import HighlightedText from '../Text/Highlighted';
import Introduction from './Introduction/Introduction';
import Journey from './Journey/Journey';
import { MyTheme } from 'src/types/types';
import Quantify from './Quantify/Quantify';
import Spacer from '../Text/Spacer';

const Features = ({ theme, classes }: WithSheet<StyleCreator<string, any>>) => {
    return (
        <div>
            <div className={classes.content}>
                <div className={cn(classes.withPadding, classes.introduction)}>
                    <HighlightedText highlightedText="Analytics" />
                    <Spacer />
                    <h1>Vortexa opens up the global flows of crude oil and refined oil products</h1>
                </div>
            </div>
            <div>
                <Introduction theme={theme} classes={classes} />
                <Spacer />
                <Flows theme={theme} classes={classes} />
                <Spacer />
                <Journey theme={theme} classes={classes} />
                <Spacer />
                <Quantify theme={theme} classes={classes} />
                <Spacer />
                <Customise theme={theme} classes={classes} />
            </div>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    content: {
        ...theme.content,
        ...theme.narrowContent
    },
    introduction: {
        margin: `0px auto`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    withPadding: {
        padding: theme.basePadding,
        '& p': {
            margin: `${2 * theme.basePadding}px 0`
        }
    },
    withFlex: {
        display: 'flex',
        height: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    asColumn: {
        flexDirection: 'column'
    },
    redDivider: {
        margin: '0px auto',
        borderLeft: '1px solid red',
        height: '100%',
        width: '0px'
    },
    hCenter: {
        justifyContent: 'center'
    },
    vCenter: {
        alignItems: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    textLeft: {
        textAlign: 'left'
    },
    largeTopMargin: {
        marginTop: '96px'
    },
    featureImageContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    dividerIcon: {
        width: '48px',
        height: '48px'
    },
    imageCell: {
        width: '374px'
    },
    imageCellContent: {
        color: 'white',
        position: 'absolute',
        textAlign: 'left',
        margin: theme.cardContentPadding,
        width: '250px'
    },
    introductionImage: {
        height: '250px',
        backgroundSize: 'contain',
        '@media(min-width: 800px)': {
            height: '500px'
        }
    },
    flowsImage: {
        height: '175px',
        backgroundSize: 'contain',
        '@media(min-width: 800px)': {
            height: '350px'
        }
    }
});

export default injectSheet(styles)(Features);
