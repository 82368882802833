import * as Bkg from './feat_journey_bkg.png';
import * as React from 'react';
import * as Screen from './feat_journey_screen.png';
import * as cn from 'classnames';

import { Col, Row } from 'react-grid-system';
import { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Divider from '../../Text/Divider';
import Spacer from '../../Text/Spacer';

const Journey = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.content}>
            <div className={cn(classes.withPadding, classes.textCenter)}>
                <h1>Track the whole journey</h1>
                <Divider centered={true} />
                <h3>
                    Vortexa achieves an unparalleled level of transparency into oil market flows by showing the whole
                    journey of a given cargo - and our dataset goes back several years.
                </h3>
            </div>
        </div>

        <Spacer />

        <div
            className={cn(classes.featureImageContainer, classes.flowsImage)}
            style={{ background: `url(${Bkg}) repeat-x`, backgroundSize: 'contain' }}
        >
            <img src={Screen} className={classes.withFlex} />
        </div>

        <div className={classes.content}>
            <div className={classes.withPadding}>
                <Row>
                    <Col xs={12} sm={12} md={4} align="center">
                        <h3>Terminal-to-terminal trade flow information</h3>
                    </Col>
                    <Col xs={12} sm={12} md={4} align="center">
                        <h3>Complex chains of ship-to-ship transfers captured</h3>
                    </Col>
                    <Col xs={12} sm={12} md={4} align="center">
                        <h3>Cargoes in floating storage monitored</h3>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
);

export default Journey;
