import * as React from 'react';
import * as defaultIcon from '../../assets/Light_Announce.png';

import { Announcement, MyTheme } from 'src/types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import ContactUsForm from '../Forms/contactUs';
import ModalComponent from '../Modal';
import { resizeImage } from '../../utils/images';

type OwnProps = {
    announcements: Announcement[];
};

class AnnouncementToolbar extends React.PureComponent<OwnProps & WithSheet<StyleCreator<string, any>>> {
    state = {
        open: false
    };

    showButton = (announcement: Announcement) =>
        (announcement.link && announcement.link.length > 0) || announcement.contact_form;

    generateLink = (announcement: Announcement) => {
        if (announcement.link && announcement.link.length > 0) {
            return announcement.link;
        }
        return '';
    };

    generateLinkText = (announcement: Announcement) => {
        if (
            announcement.link &&
            announcement.link.length > 0 &&
            announcement.button_text &&
            announcement.button_text.length === 0
        ) {
            return 'Read more';
        }
        if (announcement.button_text && announcement.button_text.length > 0) {
            return announcement.button_text;
        }
        return '';
    };

    onModalToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    render() {
        const { announcements, classes } = this.props;
        const { open } = this.state;
        const announcement = announcements.length > 0 ? announcements[0] : undefined;
        if (!announcement || !announcement.show) {
            return null;
        }

        return (
            <div className={classes.announcement}>
                <img
                    src={announcement.icon ? resizeImage(announcement.icon, 80, undefined, 50) : defaultIcon}
                    className={classes.image}
                />
                <h4>{announcement.text}</h4>
                {this.showButton(announcement) && !announcement.contact_form && (
                    <a
                        href={this.generateLink(announcement)}
                        className={classes.link}
                        target={announcement.link && announcement.link.length > 0 ? '_blank' : undefined}
                    >
                        <h4>{this.generateLinkText(announcement)}</h4>
                    </a>
                )}
                {this.showButton(announcement) && announcement.contact_form && (
                    <div className={classes.link} onClick={() => this.onModalToggle()}>
                        <h4>{this.generateLinkText(announcement)}</h4>
                    </div>
                )}
                <ModalComponent open={open} close={() => this.onModalToggle()}>
                    <ContactUsForm close={() => this.onModalToggle()} />
                </ModalComponent>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    link: {
        width: '100px',
        textAlign: 'center',
        margin: theme.basePadding,
        cursor: 'pointer'
    },
    announcement: {
        width: '100%',
        background: theme.palette.vortexa.primary,
        color: theme.palette.basic.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px'
    },
    image: {
        padding: '0 1%',
        maxWidth: '100px'
    },
    text: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
});

export default injectSheet(styles)(AnnouncementToolbar);
