import * as React from 'react';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type OwnProps = {
    highlightedText: string;
};

const HighlightedText: React.FC<WithSheet<StyleCreator<string, any>> & OwnProps> = ({ highlightedText, classes }) => {
    return (
        <div className={classes.highlight}>
            <h1 className={classes.text}>{highlightedText}</h1>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    highlight: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: theme.typography.families.primary,
        textTransform: 'uppercase',
        lineHeight: 'normal',
        color: theme.palette.basic.black
    }
});

export default injectSheet(styles)(HighlightedText);
