import * as React from 'react';
import * as icon from '../../assets/BG (1).png';

import { Col, Hidden, Row } from 'react-grid-system';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import { MyTheme } from '../../types/types';
import { validateEmail } from '../../utils/form';

const subscribeInfo = {
    title: 'Our newsletter',
    description: 'Subscribe below to receive Vortexa news and updates'
};

export const SUBSCRIBE_SECTION_ID = 'subscribe';

class Subscribe extends React.PureComponent<WithSheet<StyleCreator<string, any>>> {
    state = {
        email: '',
        error: false,
        confirmation: false
    };
    onChangeHandler = (e: any) => {
        e.preventDefault();
        this.setState({ email: e.currentTarget.value, error: false });
    };

    onSubmitHandler = () => {
        if (validateEmail(this.state.email)) {
            try {
                const url = 'https://formkeep.com/f/d432e000ce93';
                const headers = {
                    accept: 'application/javascript',
                    'Content-Type': 'application/json'
                };
                const payload = {
                    email: this.state.email
                };
                fetch(url, {
                    method: 'post',
                    headers,
                    body: JSON.stringify(payload)
                }).then(() => {
                    this.setState({ confirmation: true });
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ error: true });
        }
    };

    render() {
        const { classes } = this.props;
        const { email, error, confirmation } = this.state;
        return (
            <div id={SUBSCRIBE_SECTION_ID} className={classes.subscribe}>
                <div className={classes.container}>
                    <Row>
                        <Col xs={4} md={6}>
                            <Hidden xs sm>
                                <img className={classes.image} src={icon} />
                            </Hidden>
                        </Col>
                        <Col xs={8} md={6} className={classes.textColumn}>
                            <div className={classes.textContainer}>
                                <h1>{subscribeInfo.title}</h1>
                                <p>{subscribeInfo.description}</p>
                                <form id="subscribe_form">
                                    <input
                                        value={email}
                                        name="email"
                                        type="email"
                                        className={classes.textArea}
                                        placeholder="youremail@domain"
                                        onChange={this.onChangeHandler}
                                    />
                                </form>
                                {error && <span className={classes.errorMessage}>Invalid email</span>}
                                {confirmation && (
                                    <span className={classes.confirmationMessage}>
                                        You are now added to our mailing list!
                                    </span>
                                )}
                                <div className={classes.subscribeButton}>
                                    <Button onClick={this.onSubmitHandler}>Subscribe</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    subscribe: {
        background: theme.palette.fog.lightContainer
    },
    container: {
        background: `url('${icon}') left/contain no-repeat`,
        ...theme.content
    },
    textColumn: {
        display: 'flex',
        alignItems: 'center'
    },
    textContainer: {
        margin: theme.cardContentPadding
    },
    textArea: {
        background: 'transparent',
        border: 'none',
        borderBottom: `1px solid ${theme.palette.basic.black}`,
        margin: `${theme.basePadding}px 0px`,
        width: '100%',
        outline: 'none',
        padding: `${theme.basePadding}px 0`
    },
    image: {
        display: 'flex',
        width: '100%'
    },
    subscribeButton: {
        marginTop: theme.basePadding
    },
    errorMessage: {
        color: theme.palette.medusa.primary
    },
    confirmationMessage: {
        color: theme.palette.vortexa.primary
    }
});

export default injectSheet(styles)(Subscribe);
