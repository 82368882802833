import * as React from 'react';
import * as background from '../assets/Homepage.jpg';
import * as icon from '../assets/logo_large.png';

import { Announcement, ArticleNode, Edge, MyTheme, News, Value } from 'src/types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import AnnouncementToolbar from '../components/Announcement/toolbar';
import ArticleBar from '../components/ArticleBar';
import Features from '../components/Features';
import { Footer } from '../components/Footer';
import HelmetComponent from '../components/Helmet';
import NewsComponent from '../components/News';
import PageHeader from '../components/PageHeader';
import Spacer from '../components/Text/Spacer';
import Subscribe from '../components/Subscribe/SubscribeBanner';
import { graphql } from 'gatsby';

const homePageHeader = {
    title: 'Intelligent energy markets',
    body: `Real time information analytics with the most complete set of oil cargo data available`,
    icon,
    background
};

type OwnProps = {
    data: {
        announcements: Value<Announcement>;
        articles: Edge<ArticleNode>;
        news: Value<News>;
    };
};

const IndexPage = ({ data, classes }: WithSheet<StyleCreator<string, any>> & OwnProps) => (
    <div>
        <HelmetComponent
            title="Vortexa | Intelligent Energy Markets"
            // tslint:disable-next-line:max-line-length
            description="Vortexa is an energy intelligence provider delivering the most comprehensive view of waterborne crude oil and refined oil products available today."
        />
        <PageHeader data={homePageHeader} />
        <div className={classes.main}>
            <AnnouncementToolbar announcements={data.announcements.value} />
            <ArticleBar articles={data.articles} />
            <Spacer />
            <Features />
            {data.news.value.length > 0 && (
                <div>
                    <Spacer />
                    <NewsComponent news={data.news.value} />
                </div>
            )}
            <Spacer />
            <Subscribe />
            <Spacer />
            <Footer />
        </div>
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    main: {
        marginTop: '70vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: theme.palette.basic.white
    }
});

export default injectSheet(styles)(IndexPage);
export const query = graphql`
    query {
        announcements: butterCollection(key: { eq: "announcements" }) {
            ...Announcement
        }
        news: butterCollection(key: { eq: "news" }) {
            ...News
        }
        articles: allButterPost(limit: 2, sort: { fields: published, order: DESC }) {
            ...Articles
        }
    }
`;
