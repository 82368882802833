import * as React from 'react';
import * as cn from 'classnames';
import * as complex from './oil_complex.jpg';

import { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Divider from '../../Text/Divider';

const Flows = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.content}>
            <div className={classes.withPadding}>
                <div className={cn(classes.withPadding, classes.textCenter)}>
                    <h1 className={classes.sectionHeader}>See across the barrel</h1>
                    <Divider centered={true} />
                    <h3>
                        Vortexa's advanced cargo-tracking platform provides information at the grade level for global
                        crude oil and refined products
                    </h3>
                </div>
                <img src={complex} className={classes.fullWidth} />
            </div>
        </div>
    </div>
);

export default Flows;
