import * as React from 'react';
import * as cn from 'classnames';
import * as future from './future.jpg';

import { Col, Row } from 'react-grid-system';
import { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Divider from '../../Text/Divider';
import Spacer from '../../Text/Spacer';

const Quantify = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <div className={classes.content}>
            <div className={cn(classes.withPadding, classes.textCenter)}>
                <div>
                    <h1>Quantify the future</h1>
                    <Divider centered={true} />
                    <h3>
                        Our real-time trade flows provide a reliable indication of future arrivals at a given place. We
                        also aggregate and model data at scale to generate powerful predictions and actionable
                        intelligence.
                    </h3>
                </div>
            </div>
        </div>

        <Spacer />

        <div className={classes.content}>
            <div className={classes.withPadding}>
                <Row>
                    <Col xs={12} sm={12} md={8}>
                        <div className={cn(classes.withFlex, classes.vCenter)}>
                            <img src={future} className={classes.fullWidth} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                        <div className={cn(classes.withFlex, classes.asColumn, classes.vCenter)}>
                            <div className={cn(classes.withFlex, classes.asColumn, classes.hCenter)}>
                                <ul style={{ paddingLeft: 0 }}>
                                    <li style={style.listItems}>
                                        <h3>Future delivery dates of cargoes-on-the-water</h3>
                                    </li>
                                    <li style={style.listItems}>
                                        <h3>Shipping fixture information</h3>
                                    </li>
                                    <li style={style.listItems}>
                                        <h3>View into future freight availability</h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
);

const style = {
    listItems: {
        margin: '8px 0'
    }
};

export default Quantify;
