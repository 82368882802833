import * as React from 'react';
import * as cn from 'classnames';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type OwnProps = {
    style?: CSSProperties;
    centered?: boolean;
};

const Divider: React.FC<WithSheet<StyleCreator<string, any>> & OwnProps> = props => {
    const { classes, style, centered } = props;
    return <div className={cn(classes.divider, centered ? classes.centered : undefined)} style={style} />;
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    divider: {
        height: '2px',
        width: '200px',
        backgroundColor: theme.palette.basic.black,
        // background: `linear-gradient(to right, ${theme.palette.basic.black}, ${theme.palette.vortexa.primary}, ${
        //     theme.palette.basic.black
        // })`,
        margin: `${2 * theme.basePadding}px 0`
    },
    centered: {
        margin: `${2 * theme.basePadding}px auto`
    }
});

export default injectSheet(styles)(Divider);
